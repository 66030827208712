import Badge from '@mui/material/Badge';
import { StyleObj } from '../../@types';
import { Stack, StackProps, Typography } from '@mui/material';

const styles: StyleObj = {
  badge: {
    '& .MuiBadge-badge': { fontSize: 14, height: 24, minWidth: 24, borderRadius: '50%', p: 0 },
  },
};

type BetslipBetCountProps = StackProps & {
  numberOfBets: number;
};

const BetslipBetCount = ({ numberOfBets, ...rest }: BetslipBetCountProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2.5} p={1} {...rest}>
      <Badge badgeContent={numberOfBets} color="primary" sx={styles.badge} />
      <Typography variant="subtitle2" fontWeight={600} color="neutral.600">
        Betslip
      </Typography>
    </Stack>
  );
};

export default BetslipBetCount;
