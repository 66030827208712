import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DrawerContentProps, PaginatedData, PublishedEvent, StyleObj, Tournament } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { useFavoriteEventsCount, useLiveSports } from '../../queries';
import { getData } from '../../utils/api';
import SportsIcon from '../atoms/SportsIcon';
import InPlaySidebarSportAccordion from './InPlaySidebarSportAccordion';
import InPlaySidebarTournamentAccordion from './InPlaySidebarTournamentAccordion';

const styles = {
  nav: {
    maxWidth: 400,
  },
  accordion: {
    backgroundColor: 'neutral.800',
    '& .MuiAccordionDetails-root': {
      p: 0,
    },
    '&::before': {
      backgroundColor: 'neutral.600',
    },
    transition: 'all 300ms ease',
  },
  accordionSummary: {
    pl: 3,
    pr: 1.5,
    py: 0.5,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
    borderRadius: 0,
  },
  accordionSummaryIcon: {
    color: 'neutral.300',
  },
  loader: { color: 'neutral.400', mx: 0.5, ml: 'auto' },
} satisfies StyleObj;

const InPlaySidebar = ({ toggleVisibility }: DrawerContentProps) => {
  const [expanded, setExpanded] = useState(false);

  const { eventId } = useParams();

  const { data: publishedEvent } = useQuery({
    queryKey: [QUERY_KEYS.publishedEvents, eventId],
    queryFn: (): Promise<{ item: PublishedEvent }> =>
      getData(`events/${eventId}/published`, {
        isLive: true,
      }),
    enabled: !!eventId,
    select: (data) => data.item,
  });

  const { data: tournamentsWithFavorites, isLoading: isLoadingFavorites } = useQuery({
    queryKey: [QUERY_KEYS.tournaments, 'favorites'],
    queryFn: (): Promise<PaginatedData<Tournament>> =>
      getData('tournaments/with-events', {
        page: 1,
        limit: 50,
        isLive: true,
        withFavourites: true,
      }),
    select: (data) => data.items,
  });

  const { data: sportsData } = useLiveSports();
  const { data: favoriteEventsCount } = useFavoriteEventsCount();

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (tournamentsWithFavorites) {
      setExpanded(true);
    }
  }, [tournamentsWithFavorites]);

  const closeDrawer = () => toggleVisibility(false);

  return (
    <Box sx={styles.nav}>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        disableGutters
        elevation={0}
        sx={styles.accordion}
        key="inPlaySidebar-favorites"
      >
        <AccordionSummary
          expandIcon={
            isLoadingFavorites ? (
              <CircularProgress size={16} sx={styles.loader} />
            ) : (
              <ExpandMore sx={styles.accordionSummaryIcon} />
            )
          }
          sx={styles.accordionSummary}
        >
          <SportsIcon name="favorite" size={16} />
          <Typography variant="h5" pl={1.5} fontWeight={700}>
            Favorites {!!favoriteEventsCount?.count && `(${favoriteEventsCount?.count})`}
          </Typography>
        </AccordionSummary>
        {!tournamentsWithFavorites?.length && !isLoadingFavorites ? (
          <Typography variant="body2" pl={3} pr={1.5} pb={2} whiteSpace="normal">
            Select a star below to add leagues and events here
          </Typography>
        ) : (
          tournamentsWithFavorites?.map((tournament) => (
            <InPlaySidebarTournamentAccordion
              key={tournament.id}
              tournament={tournament}
              sportId={tournament.competition?.sportId || ''}
              closeDrawer={closeDrawer}
              selectedEventTournamentId={publishedEvent?.tournament.id || ''}
            />
          ))
        )}
      </Accordion>
      {sportsData?.items?.map((sport) => (
        <InPlaySidebarSportAccordion
          sport={sport}
          key={sport.id}
          closeDrawer={closeDrawer}
          selectedEventSportId={publishedEvent?.tournament.sport.id || ''}
          selectedEventTournamentId={publishedEvent?.tournament.id || ''}
        />
      ))}
    </Box>
  );
};

export default InPlaySidebar;
