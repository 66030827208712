import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { SanityImage, StyleObj } from '../../@types';
import { getAccessToken } from 'neofusion-fe-shared';
import { postData } from '../../utils/api';
import CMSTopContent from '../organisms/CMSTopContent';
import { getSanityImage, useSanity } from '../../hooks/useSanity';
import { LoaderContainer } from '../atoms/LoaderContainer';

const styles: StyleObj = {
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    minHeight: '1550px',
  },
  innerWrapper: {
    minHeight: '800px',
    maxWidth: '1400px',
  },
};

const CasinoPage = () => {
  const { data: backgroundImage } = useSanity<{ image: SanityImage }>('CasinoBackgroundImage');

  const [ready, setReady] = useState(false);
  const [url, setURL] = useState<string>('');

  const topContentWrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const fetchURL = async () => {
      try {
        const token = await getAccessToken();
        const response = await postData('/marketplace/generateUrl', { token }, 'casino');

        setURL(response?.url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchURL();
  }, [setURL]);

  const src = getSanityImage(backgroundImage?.[0]?.image);

  return (
    <Box width={1} height={1} sx={{ ...styles.container, backgroundImage: `url("${src}")` }}>
      <Box width={1} sx={styles.innerWrapper}>
        <Box ref={topContentWrapperRef}>
          <CMSTopContent scope="casino" />
        </Box>
        <Box
          width={1}
          sx={{
            height: `calc(100% - ${topContentWrapperRef.current?.clientHeight ?? 0}px)`,
            backgroundColor: '#fff',
          }}
        >
          {!ready && <LoaderContainer />}
          <iframe
            title="casino"
            src={url}
            onLoad={() => setReady(true)}
            style={{
              border: 'none',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default CasinoPage;
