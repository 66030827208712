import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { PaginatedData, Sport, StyleObj, Tournament } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';
import SportsIcon from '../atoms/SportsIcon';
import SidebarTournamentAccordion from './InPlaySidebarTournamentAccordion';

const styles: StyleObj = {
  accordion: {
    backgroundColor: 'neutral.800',
    '& .MuiAccordionDetails-root': {
      p: 0,
    },
    '&::before': {
      backgroundColor: 'neutral.600',
    },
    ':hover': {
      backgroundColor: 'neutral.700',
    },
    transition: 'height 300ms ease',
  },
  accordionSummary: {
    pl: 3,
    pr: 1.5,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
    borderRadius: 0,
    borderColor: 'primary.main',
  },
  accordionSummaryIcon: {
    color: 'neutral.300',
  },
  loader: { color: 'neutral.400', mx: 0.5, ml: 'auto' },
};

type Props = {
  sport: Sport;
  closeDrawer: () => void;
  selectedEventSportId: string;
  selectedEventTournamentId: string;
};

const InPlaySidebarSportAccordion = ({
  sport,
  selectedEventSportId,
  selectedEventTournamentId,
  closeDrawer,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (sport.id === selectedEventSportId) {
      setExpanded(true);
    }
  }, [selectedEventSportId, sport.id]);

  const toggleAccordion = () => {
    setExpanded((prev) => !prev);
  };

  const { data: tournamentsData, isInitialLoading } = useQuery({
    queryKey: [QUERY_KEYS.tournaments, sport.id],
    queryFn: (): Promise<PaginatedData<Tournament>> =>
      getData('tournaments/with-events', {
        page: 1,
        limit: 100,
        sportId: sport.id,
        isLive: true,
        matchView: true,
      }),
    enabled: expanded,
  });

  return (
    <Accordion
      expanded={!!expanded}
      onChange={toggleAccordion}
      disableGutters
      elevation={0}
      sx={styles.accordion}
      key={sport.id}
    >
      <AccordionSummary sx={{ borderBottom: expanded ? '1px solid' : 'none', ...styles.accordionSummary }}>
        <SportsIcon name={sport.icon} size={16} />
        <Typography variant="h5" pl={1.5} fontWeight={700} noWrap>
          {sport.name}
        </Typography>
        {isInitialLoading && <CircularProgress size={16} sx={styles.loader} />}
      </AccordionSummary>
      <AccordionDetails>
        {tournamentsData?.items.map((tournament) => (
          <SidebarTournamentAccordion
            key={tournament.id}
            tournament={tournament}
            sportId={sport.id}
            closeDrawer={closeDrawer}
            selectedEventTournamentId={selectedEventTournamentId}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default InPlaySidebarSportAccordion;
