import React from 'react';
import { Box } from '@mui/material';
import { SanityTopItem, StyleObj } from '../../@types';
import { Link } from 'react-router-dom';
import { getSanityImage } from '../../hooks/useSanity';

const styles: StyleObj = {
  container: {
    cursor: 'pointer',
    marginBottom: 0,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    maxHeight: '300px',
    minHeight: '200px',
    height: '33vw',
    flex: '1 0 auto',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
};

type CMSCarouselCasinoItemProps = {
  item: SanityTopItem;
};

const CMSCarouselCasinoItem = ({ item }: CMSCarouselCasinoItemProps) => {
  const src = getSanityImage(item.image);
  return (
    <Box sx={styles.container} key={item.title}>
      <Link to={item?.callToActionURL ?? '/'} style={{ textDecoration: 'unset', color: 'unset' }}>
        <Box sx={{ ...styles.imageContainer, backgroundImage: `url("${src}")` }} />
      </Link>
    </Box>
  );
};

export default CMSCarouselCasinoItem;
