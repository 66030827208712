import { PaletteOptions, createTheme } from '@mui/material/styles';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

const TYPOGRAPHY = {
  fontFamily: 'Open Sans, sans-serif',
  h1: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '40px',
    letterSpacing: 0,
  },
  h2: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: 0.9,
  },
  h3: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: 0.9,
  },
  h4: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0.8,
  },
  h5: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0.1,
  },
  h6: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0.5,
  },
  subtitle1: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0.8,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: 0,
  },
  subtitle3: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0.5,
  },
  body1: {
    letterSpacing: 0.5,
  },
  body2: {
    letterSpacing: 0.5,
    lineHeight: '20px',
  },
  body3: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: 0.5,
  },
  body4: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '10px',
    letterSpacing: 0.5,
  },
  caption: {
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
} as const;

const PALETTE: PaletteOptions = {
  primary: {
    25: '#F7FAF7',
    50: '#E7F0E7',
    100: '#A8C2B3',
    200: '#8FB29F',
    300: '#76A38B',
    400: '#5D967B',
    500: '#408B6B',
    600: '#0F6E4E',
    700: '#0E5841',
    800: '#1F4537',
    900: '#172922',

    main: '#0F6E4E',
  },
  secondary: {
    25: '#FFFAE5',
    50: '#FFF6CA',
    100: '#FFF1B0',
    200: '#FFED95',
    300: '#FEE87B',
    400: '#FEE360',
    500: '#FEDF46',
    600: '#FEDA2B',
    700: '#BFA320',
    800: '#7F6D16',
    900: '#40370B',

    main: '#FEDA2B',
  },
  error: {
    25: '#FEF0F0',
    50: '#FEE2E2',
    100: '#FED4D4',
    200: '#FEC6C6',
    300: '#FDB2B2',
    400: '#FB8C8C',
    500: '#FA6565',
    600: '#F93F3F',
    700: '#8C1D18',
    800: '#601410',
    900: '#410E0B',
  },
  neutral: {
    25: '#F8F8F8',
    50: '#F0F0F0',
    100: '#E1E1E1',
    200: '#D7D7D7',
    300: '#888888',
    400: '#707070',
    500: '#585858',
    600: '#4C4C4C',
    700: '#383838',
    800: '#2F2F2F',
    900: '#181818',
  },

  info: {
    100: '#ACFDE3',
    300: '#59FBC8',
    500: '#57E4B9',
    600: '#55CDA9',
    700: '#469F84',

    main: '#2FFABA',
  },
  background: {
    default: '#383838',
  },
  text: {
    primary: '#fff',
  },
} as const;

const theme = createTheme({
  typography: TYPOGRAPHY,
  palette: PALETTE,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            display: 'block',
            '@media (max-width: 899.95px)': {
              display: 'none',
            },
            backgroundColor: 'transparent',
            width: 16,
            border: '1px solid',
            borderColor: PALETTE?.neutral?.[800],
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: PALETTE?.neutral?.[500],
            backgroundClip: 'padding-box',
            border: '4px solid transparent',
            minHeight: 24,
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: PALETTE?.neutral?.[500],
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: PALETTE?.neutral?.[500],
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: PALETTE?.neutral?.[500],
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          height: 40,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: '20px',
          padding: '10px 24px',
          textTransform: 'none',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: '16px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: PALETTE?.neutral?.[700],
        },
      },
    },
  },
});

export default theme;
