import { SvgIcon, SxProps, Theme } from '@mui/material';
import { SportsType } from '../../@types';
import { SPORTS_ASSETS_MAP } from '../../constants/sports';

type SportsIconProps = {
  name: SportsType;
  size?: number | string;
  sx?: SxProps<Theme>;
};

const SportsIcon = ({ name, size, sx }: SportsIconProps) => {
  const IconComponent = SPORTS_ASSETS_MAP[name]?.icon;

  if (!IconComponent) {
    return null;
  }

  return (
    <SvgIcon
      component={IconComponent}
      viewBox="0 0 32 32"
      sx={{ width: size || 'initial', height: size || 'initial', ...sx }}
    />
  );
};

export default SportsIcon;
