import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Market, PublishedEventListItem } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { MessageBetType } from '../../helpers/betslipMessageParser';
import eventListItemUpdater from '../../helpers/eventListItemUpdater';
import topMarketUpdater from '../../helpers/eventTopMarketUpdater';
import useWebsocket, { MessageType } from '../../hooks/useWebsocket';
import { usePublishedEvents } from '../../queries';
import { getData } from '../../utils/api';
import Heading from '../atoms/Heading';
import EventsTable from '../organisms/tables/EventsTable';

type Props = {
  sportId?: string;
};

const InPlayPreviewTable = ({ sportId }: Props) => {
  const { data: liveEventsCount } = useQuery({
    queryKey: [QUERY_KEYS.liveEventsCount],
    queryFn: (): Promise<{ count: number }> => getData('events/live/count'),
  });

  const { data: upcomingLiveEventsData } = usePublishedEvents({
    queryKey: [QUERY_KEYS.upcomingLiveEvents],
    queryParams: {
      limit: 10,
      sportId,
      isLive: true,
    },
    options: {
      enabled: !!sportId,
      onSuccess: (data) => {
        const topMarketsData: Record<string, Market | null> = {};
        data?.items?.forEach((event) => {
          joinRoom(`${event.id}_reduced`);

          if (event.topMarket) {
            joinTopMarketRoom(`${event.id}_${event.topMarket?.marketType.id}`);
          }

          topMarketsData[event.id] = event.topMarket;
        });

        setEvents(data?.items || []);
        setTopMarkets(topMarketsData);
      },
    },
  });

  const [events, setEvents] = useState<PublishedEventListItem[]>(upcomingLiveEventsData?.items || []);
  const [topMarkets, setTopMarkets] = useState<Record<string, Market | null>>({});

  const updaterCallback = (data: Record<string, MessageType<MessageBetType>>) => {
    setEvents((prevState) => {
      const updatedEventId = Object.keys(data)[0];

      const eventToUpdate = prevState?.find((event) => event.id === updatedEventId);
      if (!eventToUpdate) return prevState;

      const updatedEvent = eventListItemUpdater(eventToUpdate, data[updatedEventId]);

      return prevState.map((event) => (event.id === updatedEventId ? updatedEvent : event));
    });
  };

  const { joinRoom } = useWebsocket<MessageType<MessageBetType>>({
    callback: updaterCallback,
  });

  const topMarketUpdaterCallback = (data: Record<string, MessageType<MessageBetType>>) => {
    setTopMarkets((prevState) => {
      const updatedEventId = Object.keys(data)[0];

      const marketToUpdate = prevState[updatedEventId];
      if (!marketToUpdate) return prevState;

      const updatedMarket = topMarketUpdater(marketToUpdate, data[updatedEventId]);
      return { ...prevState, [updatedEventId]: updatedMarket };
    });
  };

  const { joinRoom: joinTopMarketRoom } = useWebsocket<MessageType<MessageBetType>>({
    callback: topMarketUpdaterCallback,
  });

  return (
    <>
      <Heading to="../in-play">
        <Heading.Title>
          Live{' '}
          <Typography component="span" variant="inherit" color="secondary">
            IN-PLAY
          </Typography>
        </Heading.Title>
        <Heading.Link>{liveEventsCount?.count} Events</Heading.Link>
      </Heading>
      {!!events.length && sportId && (
        <EventsTable
          data={events.map((event) => ({
            ...event,
            topMarket: topMarkets[event.id],
          }))}
          showTableHead
          topMarketType={upcomingLiveEventsData?.topMarketType}
          inPlay
        />
      )}
    </>
  );
};

export default InPlayPreviewTable;
