import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { BankerMenuOptions, StakeErrorType, StyleObj } from '../../@types';
import { CURRENCY, MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE } from '../../constants';
import { BetslipEvent, useBetslip } from '../../contexts/BetslipContext';
import {
  extractSpecialValuesFromOutcomeName,
  getSpecialValuesDisplayValue,
  parseNumberWithDecimals,
  prepareStakeMessages,
} from '../../helpers';
import { OddsChangeStatus } from '../../helpers/betslipMessageParser';
import NumberInput from '../atoms/NumberInput';
import { useGlobalTicketConditions } from '../../queries';
import { useEffect } from 'react';

const makeStyles = (isWaysEvent: boolean, isBanker?: boolean, isDisabled?: boolean): StyleObj => ({
  container: isWaysEvent
    ? {
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: '5px',
          backgroundColor: (theme) => theme.palette.primary.main,
        },
      }
    : {},
  betInfo: {
    background: (theme) => theme.palette.neutral[100],
    borderBottom: '1px solid #D9D9D9',
    py: 1,
    pr: 1.5,
    pl: 0,
    opacity: isDisabled ? 0.5 : 1,
  },
  removeBtnContainer: {
    backgroundColor: isDisabled ? 'rgba(225,225,225,0.5)' : 'neutral.100',
  },
  removeBetButton: {
    pt: 1.25,
    pl: 1.25,
    pr: 0,
    alignSelf: 'start',
    ':hover': { background: 'none' },
  },
  stakeInput: {
    width: 90,
    height: 40,
    flexDirection: 'row',
    '& fieldset': { border: 'none' },
    '& .MuiInputBase-input': {
      color: (theme) => theme.palette.primary.main,
      textAlign: 'right',
      fontWeight: 600,
      fontSize: 14,
    },
    background: 'white',
  },
  singleStake: { background: (theme) => theme.palette.neutral[100], width: 90, opacity: isDisabled ? 0.5 : 1 },
  bankerContainer: {
    display: 'flex',
    alignItems: 'center',
    pl: 1,
    background: (theme) => theme.palette.neutral[100],
    borderBottom: `1px solid #D9D9D9`,
  },
  bankerButton: {
    background: (theme) => (isBanker ? theme.palette.info[700] : 'white'),
    color: isBanker ? 'white' : 'neutral.600',
    height: 48,
    minWidth: 0,
    width: 48,
    mr: 2,
    p: 3,
    borderRadius: 0,
    ':hover': {
      color: 'white',
    },
  },
});

type BetslipItemProps = {
  value: string;
  onChange: (value: string) => void;
  bet: BetslipEvent;
  selectedMenuItem: BankerMenuOptions;
  onRemoveBet: (outcomeId: string) => void;
  errorMessages?: { errorType: StakeErrorType };
};

const BetslipItem = ({ bet, selectedMenuItem, value, onChange, onRemoveBet, errorMessages }: BetslipItemProps) => {
  const {
    eventId,
    outcomeId,
    outcomeName,
    outcomeShortName,
    odds,
    oddsChange,
    eventName,
    marketName,
    marketType,
    specialValues,
    banker,
    disabled,
  } = bet;

  const { bets, removeBet, toggleBanker, isMaxBankerCountReached } = useBetslip();

  const handleRemoveBet = (outcomeId: string) => {
    removeBet(outcomeId);
    onRemoveBet(outcomeId);
  };

  const getOddsTextBg = (oddsChangeStatus: OddsChangeStatus | undefined): string => {
    switch (oddsChangeStatus) {
      case OddsChangeStatus.increased:
        return 'primary.main';
      case OddsChangeStatus.decreased:
        return 'error.600';
      default:
        return 'initial';
    }
  };

  const getOddsTextColor = (oddsChangeStatus: OddsChangeStatus | undefined): string => {
    switch (oddsChangeStatus) {
      case OddsChangeStatus.increased:
      case OddsChangeStatus.decreased:
        return 'text.primary';
      default:
        return 'neutral.600';
    }
  };

  const isWaysEvent = bets.filter((b) => b.eventId === eventId).length > 1;
  const isBanker = banker;
  const isDisabled = disabled;
  const showBankers = selectedMenuItem === 'bankers';
  const firstSpecialValueModel = specialValues?.[0]?.model;
  const marketNameToDisplay = specialValues
    ? (marketType?.shortName ? marketType?.shortName : marketType?.name) + ', ' + marketType?.eventPartName
    : marketName;
  const styles = makeStyles(isWaysEvent, isBanker, isDisabled);

  const specialValuesForAsianHandicapWithScore =
    marketType?.name === MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE ? outcomeName.split(' ').pop() : null;

  const specialValuesFromOutcomeName = extractSpecialValuesFromOutcomeName(outcomeName, specialValues);

  const { data: globalTicketConditions } = useGlobalTicketConditions();

  useEffect(() => {
    //trigger update of the stake value when odds are updated
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odds]);

  const betslipTicketType = bet.isLive ? 'inPlay' : 'preMatch';
  const stakeMessage = prepareStakeMessages(betslipTicketType, globalTicketConditions, errorMessages?.errorType);
  const { type: stakeErrorType, amount: stakeErrorAmount } = stakeMessage ?? {};

  return (
    <Stack direction="row" sx={styles.container}>
      <Box sx={styles.removeBtnContainer}>
        <IconButton
          color="primary"
          size="small"
          sx={styles.removeBetButton}
          onClick={() => {
            handleRemoveBet(outcomeId);
          }}
          disabled={isMaxBankerCountReached && !isBanker && showBankers}
        >
          <Close sx={{ fontSize: 16 }} />
        </IconButton>
      </Box>
      <Stack direction="row" justifyContent="space-between" flexGrow={1} sx={styles.betInfo}>
        <Stack spacing={0.5} flexGrow={1} textAlign="left" px={1}>
          <Typography variant="subtitle2" color="neutral.600">
            {firstSpecialValueModel?.modelType === 'player' ? `(${firstSpecialValueModel.playerTeamName}) ` : ''}
            {specialValuesForAsianHandicapWithScore || outcomeShortName
              ? `${specialValuesForAsianHandicapWithScore || outcomeShortName} ${getSpecialValuesDisplayValue(specialValuesFromOutcomeName, specialValues)}`
              : outcomeName}
          </Typography>
          <Typography variant="h6" color="neutral.600">
            {eventName}
          </Typography>
          <Typography variant="body2" color="neutral.600">
            {marketNameToDisplay}
          </Typography>
          {stakeErrorType && (
            <Typography variant="body3" fontSize={9} textAlign="center" color="error.600">
              {stakeErrorType} is {CURRENCY.symbol}
              {stakeErrorAmount && parseNumberWithDecimals(stakeErrorAmount, 2)}
            </Typography>
          )}
        </Stack>
        <Typography
          variant="subtitle2"
          color={getOddsTextColor(oddsChange)}
          bgcolor={getOddsTextBg(oddsChange)}
          py={0.5}
          px={0.5}
          height="fit-content"
        >
          {odds}
        </Typography>
      </Stack>
      {showBankers ? (
        <Box sx={styles.bankerContainer}>
          <Button
            disableRipple
            disabled={isMaxBankerCountReached && !isBanker}
            onClick={() => {
              toggleBanker(outcomeId);
            }}
            sx={styles.bankerButton}
          >
            B
          </Button>
        </Box>
      ) : (
        <Stack alignItems="end" spacing={0.5} sx={styles.singleStake}>
          <>
            <NumberInput
              size="small"
              variant="standard"
              placeholder="Stake"
              sx={styles.stakeInput}
              onChange={onChange}
              value={value as string}
              disabled={isDisabled}
            />
            <Typography variant="body3" pr={0.5} fontSize={9} color="neutral.600">
              To Return: {CURRENCY.symbol}
              {value ? (Number(odds) * Number(value)).toFixed(2) : '0.00'}
            </Typography>
          </>
        </Stack>
      )}
    </Stack>
  );
};

export default BetslipItem;
