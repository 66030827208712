import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  betslipInfo: {
    background: (theme) => theme.palette.neutral[100],
    py: 1,
    px: 1.5,
    height: 40,
    mt: 1.5,
    mb: 0,
    mx: 1.5,
    borderRadius: 2,
  },
};

type BetslipErrorMessageProps = {
  message: string;
};

const BetslipErrorMessage = ({ message }: BetslipErrorMessageProps) => (
  <Stack direction="row" alignItems="center" gap={1} sx={styles.betslipInfo}>
    <InfoIcon color="error" />
    <Typography variant="body3" color="neutral.600">
      {message}
    </Typography>
  </Stack>
);

export default BetslipErrorMessage;
