import { Stack, TableCell, TableCellProps, Typography } from '@mui/material';
import { isEmpty, merge } from 'lodash-es';
import { Outcome, SpecialValue, StyleObj } from '../../@types';
import { MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE } from '../../constants';
import { useBetslip } from '../../contexts/BetslipContext';
import { extractSpecialValuesFromOutcomeName, getOutcomeName, getSpecialValuesDisplayValue } from '../../helpers';

const makeStyles = (selected: boolean, hideOutcomeName?: boolean, disabled?: boolean): StyleObj => ({
  outcomeCell: {
    flex: 1,
    color: selected ? 'primary.600' : 'secondary.main',
    backgroundColor: selected ? 'neutral.100' : 'neutral.800',
    opacity: disabled ? 0.3 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
    borderBottom: '1px solid',
    borderBottomColor: hideOutcomeName ? 'neutral.600' : 'primary.main',
    p: {
      xs: 1,
      md: 2,
    },
    '&:hover': {
      backgroundColor: disabled ? 'neutral.800' : selected ? 'neutral.100' : 'neutral.600',
    },
    span: {
      typography: {
        xs: 'body3',
        md: 'body2',
      },
    },
  },
  oddsStack: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: {
      xs: 0.25,
      md: 0.5,
    },
  },
});

type OutcomeTableCellProps = TableCellProps & {
  outcome?: Outcome | null; // optional because we want to render empty cell if there is no outcome
  disabled?: boolean;
  specialValues?: SpecialValue[];
  onClick: () => void;
  hideOutcomeName?: boolean;
  isMarketSingleDisplayType: boolean;
  marketTypeName?: string;
};

const OutcomeTableCell = ({
  outcome,
  specialValues,
  onClick,
  hideOutcomeName = false, // used to hide outcome name and special values on In play page
  disabled,
  isMarketSingleDisplayType,
  marketTypeName,
  ...rest
}: OutcomeTableCellProps) => {
  const { isOutcomeSelected } = useBetslip();

  const isEmptyCell = !outcome || outcome.odds === '1.00'; // if odds is 1.00, show empty cell

  const selected = !isEmptyCell ? isOutcomeSelected(outcome.id) : false;
  const hasSpecialValues = outcome && !isEmpty(specialValues);

  const styles = makeStyles(selected, hideOutcomeName, disabled || isEmptyCell || !outcome?.isAvailable);

  const outcomeName =
    outcome && !hideOutcomeName && !hasSpecialValues ? getOutcomeName(outcome, isMarketSingleDisplayType) : null;

  const specialValuesFromOutcomeName = hasSpecialValues
    ? extractSpecialValuesFromOutcomeName(outcome.name, specialValues)
    : null;

  const specialValuesForAsianHandicapWithScore =
    marketTypeName === MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE ? outcome?.name.split(' ').pop() : null;

  if (isEmptyCell) {
    return (
      <TableCell align="center" sx={styles.outcomeCell}>
        <Typography color="white" component="span" textAlign="center">
          -
        </Typography>
      </TableCell>
    );
  }

  return (
    <TableCell
      align="center"
      onClick={disabled || !outcome?.isAvailable || isEmptyCell ? undefined : onClick}
      sx={merge(styles.outcomeCell, rest.sx)}
    >
      <Stack sx={styles.oddsStack}>
        {hasSpecialValues && (
          <Typography color={selected ? 'neutral.600' : 'white'} component="span" noWrap>
            {specialValuesForAsianHandicapWithScore ||
              getSpecialValuesDisplayValue(specialValuesFromOutcomeName, specialValues)}
          </Typography>
        )}
        {outcomeName && (
          <Typography color={selected ? 'neutral.600' : 'white'} component="span">
            {outcomeName}
          </Typography>
        )}
        <Typography id={outcome.id} component="span">
          {outcome.odds}
        </Typography>
      </Stack>
    </TableCell>
  );
};

export default OutcomeTableCell;
