import { compact, findKey, isEmpty } from 'lodash-es';
import { Location } from 'react-router-dom';
import {
  BetslipTicketType,
  BetslipWithBetsExtendedInfo,
  BettingSlipAcceptStatus,
  GlobalTicketConditionsResponse,
  Market,
  Outcome,
  SpecialValue,
  StakeErrorType,
  UserBalance,
} from '../@types';
import { BETSLIP_TICKET_TYPES, BETTING_TYPE_HIT_COUNT_MAP, CURRENCY } from '../constants';
import { MESSAGES } from '../constants/messages';
import { BetslipEvent } from '../contexts/BetslipContext';
import { maxNullable, minNullable } from '../utils';
import { calculateMaxStakeAmountForNormalBettingSlip } from 'sportsbook-shared-module';

export const isValidNumericString = (inputString: string): boolean => {
  const numericPattern = /^(0|[1-9]\d*)$|^0\.\d{0,2}$|^[1-9]\d*\.\d{0,2}$/;

  return numericPattern.test(inputString) || inputString === '';
};

export const isDev = (): boolean => process.env.NODE_ENV === 'development';

export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }

  return true;
};

export type SidebarType = 'betting' | 'myAccount' | 'inPlay';
export type GetSidebarType = (location: Location) => SidebarType | null;
export const getSidebarType: GetSidebarType = ({ pathname }) => {
  if (pathname.includes('my-account')) {
    return 'myAccount';
  }

  if (pathname.includes('sports')) {
    return 'betting';
  }

  if (pathname.includes('in-play/match-view')) {
    return 'inPlay';
  }

  return null;
};

const getSystemBettingType = (requiredHitCount: number) =>
  findKey(BETTING_TYPE_HIT_COUNT_MAP, (item) => item === requiredHitCount);

export const getStake = (bet?: BetslipWithBetsExtendedInfo) => {
  if (!bet) return null;

  if (bet.bettingSlipType === 'Single') {
    return `${CURRENCY.symbol}${parseNumberWithDecimals(bet.stakeAmountPerCombination, 2)} @ ${parseNumberWithDecimals(bet.totalOdds, 2)}`;
  }

  return compact([
    bet.combinationsCount > 1 && `${bet.combinationsCount}x`,
    CURRENCY.symbol,
    parseNumberWithDecimals(bet.stakeAmountPerCombination, 2),
    ' @ ',
    bet.totalOdds && `${parseNumberWithDecimals(bet.totalOdds, 2)} `,
    getSystemBettingType(bet.requiredHitCount),
  ]).join('');
};

export const getResolveStatus = (tab: string) => {
  if (tab === 'all') return undefined;
  if (tab === 'others') return ['void']; // There's no others status on BE, it's been decided to use void instead until implementation
  return [tab];
};

export const isNumberString = (value: string) => !isNaN(Number(value));
export const parseNumberWithDecimals = (value: string | number, decimals: number) => Number(value).toFixed(decimals);

export const getEventPartScoreColor = (eventPartName: string) => {
  if (eventPartName?.toLowerCase() === 'whole match') {
    return 'secondary';
  } else if (eventPartName?.toLocaleLowerCase().includes('half')) {
    return 'neutral.300';
  } else {
    return 'neutral.50';
  }
};

export const getBettingSlipSnackbarMessage = (status: BettingSlipAcceptStatus, count: number) => {
  const messageStart = `${count} ${count > 1 ? ' tickets' : ' ticket'}`;
  switch (status) {
    case 'pending':
      return `${messageStart} ${MESSAGES.requireConfirmation}`;
    case 'rejected':
      return ` ${messageStart} ${MESSAGES.rejected}`;
    case 'accepted':
      return `${messageStart} ${MESSAGES.placeBetSuccess?.toLowerCase()}`;
    default:
      return '';
  }
};

export const getBetTypeLabel = (requiredHitCount: number) => {
  if (requiredHitCount === 1) {
    return 'Single';
  } else if (requiredHitCount === 2) {
    return 'Double';
  } else if (requiredHitCount === 3) {
    return 'Treble';
  }
  return `${requiredHitCount} Fold`;
};

export const getBalanceAmount = (balances: UserBalance[] | undefined, type: string) => {
  if (!balances) return;
  return balances?.find((balance) => balance.type === type)?.balance;
};

export const getOutcomeName = (outcome: Outcome, isMarketSingleDisplayType: boolean) => {
  if (isMarketSingleDisplayType) {
    return outcome.shortName || outcome.name;
  }

  return outcome.outcomeType?.shortName || outcome.outcomeType?.name || '';
};

export const sortMarketsBySpecialValues = (a: Market, b: Market) => {
  return (a.specialValues?.[0].value || 0) - (b.specialValues?.[0].value || 0);
};

const isNumeric = (value: string) => {
  const number = parseFloat(value);
  return !isNaN(number) && isFinite(number);
};

export const extractSpecialValuesFromOutcomeName = (outcomeName: string, specialValues: SpecialValue[] = []) => {
  const regex = /\(([^()]+(?:\([^()]*\)[^()]*)*)\)/g;
  const specialValuesFinal: string[] = [];
  let match;

  while ((match = regex.exec(outcomeName)) !== null) {
    const outcomeNameBracketsValues = match[1].split(' ') || [];
    const SVValues = specialValues.map((sv) => Math.abs(sv.value || 0));

    outcomeNameBracketsValues.forEach((value) => {
      if (isNumeric(value)) {
        const absoluteValue = Math.abs(parseFloat(value));
        if (SVValues.includes(absoluteValue)) {
          specialValuesFinal.push(value);
        }
      } else {
        if ((value !== '(W)' && value !== 'W') || !isEmpty(specialValuesFinal)) {
          specialValuesFinal.push(value);
        }
      }
    });
  }

  return specialValuesFinal;
};

export const getOutcomeCellWidth = (outcomesLength: number) => {
  if (outcomesLength === 6) {
    return '33.33%';
  }

  if (outcomesLength > 4) {
    return '25%';
  }

  return `calc(100% / ${outcomesLength})`;
};

export const getSpecialValuesDisplayValue = (
  specialValuesFromOutcomeName: string[] | null,
  specialValues?: SpecialValue[]
) => {
  if (isEmpty(specialValuesFromOutcomeName)) {
    return specialValues?.map((specialValue) => specialValue.value).join(' ') || '';
  }

  return specialValuesFromOutcomeName?.join(' ');
};
export const analyzeBetslip = (
  bets: BetslipEvent[]
): { preMatchCount: number; inPlayCount: number; betslipTicketType: BetslipTicketType } => {
  let preMatchCount = 0;
  let inPlayCount = 0;

  for (const bet of bets) {
    if (bet.isLive) {
      inPlayCount++;
    } else {
      preMatchCount++;
    }
  }

  let betslipTicketType: BetslipTicketType = BETSLIP_TICKET_TYPES.inPlay;

  if (inPlayCount && preMatchCount) {
    betslipTicketType = BETSLIP_TICKET_TYPES.mix;
  } else if (inPlayCount === 0) {
    betslipTicketType = BETSLIP_TICKET_TYPES.preMatch;
  }

  return { preMatchCount, inPlayCount, betslipTicketType };
};

export const calculateRoundedMinStakeAmount = (combinations: number, minStake: number) => {
  let rawStakeAmount = minStake / combinations;
  let roundedStakeAmount = +rawStakeAmount.toFixed(2);
  let minTicketStake = combinations * roundedStakeAmount;
  while (minTicketStake < minStake) {
    rawStakeAmount += 0.01;
    roundedStakeAmount = +rawStakeAmount.toFixed(2);
    minTicketStake = combinations * roundedStakeAmount;
  }
  return roundedStakeAmount;
};

export const mapStakeToCondition = (
  ticketType: BetslipTicketType,
  errorType: StakeErrorType,
  maxWinLimit: number,
  conditions?: GlobalTicketConditionsResponse,
  combinationsNumber = 1
) => {
  let stakeLimit: number | null | undefined;
  let minStake: number | undefined;
  let maxStake: number | undefined;
  switch (errorType) {
    case 'min':
      minStake = conditions?.[ticketType].minTicketStake ?? 0;
      stakeLimit = calculateRoundedMinStakeAmount(combinationsNumber, minStake);
      break;
    case 'minSingleInPlay':
      minStake = conditions?.inPlay?.minTicketStake ?? 0;
      stakeLimit = calculateRoundedMinStakeAmount(combinationsNumber, minStake);
      break;
    case 'minSinglePreMatch':
      minStake = conditions?.preMatch?.minTicketStake ?? 0;
      stakeLimit = calculateRoundedMinStakeAmount(combinationsNumber, minStake);
      break;
    case 'max':
      maxStake = conditions?.[ticketType].maxTicketStake ?? Infinity;
      stakeLimit = calculateMaxStakeAmountForNormalBettingSlip(combinationsNumber, maxStake);
      break;
    case 'maxSingleInPlay':
      maxStake = conditions?.inPlay?.maxStakeSingle ?? conditions?.inPlay?.maxTicketStake ?? Infinity;
      stakeLimit = calculateMaxStakeAmountForNormalBettingSlip(combinationsNumber, maxStake);
      break;
    case 'maxSinglePreMatch':
      maxStake = conditions?.preMatch?.maxStakeSingle ?? conditions?.preMatch?.maxTicketStake ?? Infinity;
      stakeLimit = calculateMaxStakeAmountForNormalBettingSlip(combinationsNumber, maxStake);
      break;
    case 'maxWinLimit':
      stakeLimit = combinationsNumber > 1 ? 0 : maxWinLimit;
      break;
    default:
      stakeLimit = 0;
      break;
  }
  return stakeLimit;
};

export const prepareStakeMessages = (
  betslipTicketType: BetslipTicketType,
  globalTicketConditions?: GlobalTicketConditionsResponse,
  errorType?: StakeErrorType
) => {
  switch (errorType) {
    case 'min':
      if (globalTicketConditions?.[betslipTicketType]?.minTicketStake) {
        return { type: 'Min stake', amount: globalTicketConditions[betslipTicketType].minTicketStake };
      }
      break;
    case 'minSingleInPlay':
      if (globalTicketConditions?.[BETSLIP_TICKET_TYPES.inPlay]?.minTicketStake) {
        return {
          type: 'Min in-play single stake',
          amount: globalTicketConditions[BETSLIP_TICKET_TYPES.inPlay].minTicketStake,
        };
      }
      break;
    case 'minSinglePreMatch':
      if (globalTicketConditions?.[BETSLIP_TICKET_TYPES.preMatch]?.minTicketStake) {
        return {
          type: 'Min pre-match single stake',
          amount: globalTicketConditions[BETSLIP_TICKET_TYPES.preMatch].minTicketStake,
        };
      }
      break;
    case 'max':
      if (globalTicketConditions?.[betslipTicketType]?.maxTicketStake) {
        return { type: 'Max stake', amount: globalTicketConditions[betslipTicketType].maxTicketStake };
      }
      break;
    case 'maxSingleInPlay':
      if (globalTicketConditions?.[BETSLIP_TICKET_TYPES.inPlay]?.maxStakeSingle) {
        return {
          type: 'Max in-play single stake',
          amount: globalTicketConditions[BETSLIP_TICKET_TYPES.inPlay].maxStakeSingle,
        };
      }
      break;
    case 'maxSinglePreMatch':
      if (globalTicketConditions?.[BETSLIP_TICKET_TYPES.preMatch]?.maxStakeSingle) {
        return {
          type: 'Max pre-match single stake',
          amount: globalTicketConditions[BETSLIP_TICKET_TYPES.preMatch].maxStakeSingle,
        };
      }
      break;
    case 'maxWinLimit':
      return {
        type: 'Max win amount ',
        amount: globalTicketConditions?.[betslipTicketType]?.maxWinning,
      };
    default:
      break;
  }
};

export const generateMixTicketConditions = (conditions: GlobalTicketConditionsResponse) => {
  return {
    ...conditions,
    mix: {
      minTicketStake: minNullable(conditions.inPlay.minTicketStake, conditions.preMatch.minTicketStake),
      maxTicketStake: maxNullable(conditions.inPlay.maxTicketStake, conditions.preMatch.maxTicketStake),
      maxStakeSingle: null,
      maxSelections: maxNullable(conditions.inPlay.maxSelections, conditions.preMatch.maxSelections),
      maxWinning: maxNullable(conditions.inPlay.maxWinning, conditions.preMatch.maxWinning),
    },
  };
};

export const getErrorMessageType = (
  isSingle: boolean,
  ticketType: BetslipTicketType,
  prefix: 'min' | 'max'
): StakeErrorType => {
  const singleTicketTypeMap: Record<BetslipTicketType, string> = {
    inPlay: 'SingleInPlay',
    preMatch: 'SinglePreMatch',
    mix: '',
  };

  const suffix = isSingle ? singleTicketTypeMap[ticketType] : '';
  const errorMessageType = `${prefix}${suffix}` as StakeErrorType;

  return errorMessageType;
};

export const formatTennisScore = (score: number | null) => {
  return score === 50 ? 'A' : score;
};
