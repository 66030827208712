import { ChevronRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { StyleObj, Tournament } from '../../@types';

type TopOfferItemProps = {
  tournament: Tournament;
};

const styles: StyleObj = {
  container: (theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.neutral[600]}`,
    px: {
      xs: 1.25,
      md: 3,
    },
    py: {
      xs: 0.75,
      md: 1.5,
    },
    '> p': {
      typography: {
        xs: 'body3',
        md: 'body1',
      },
    },
  }),
  icon: {
    fontSize: {
      xs: 16,
      md: 24,
    },
  },
};

const TopOfferItem = ({ tournament }: TopOfferItemProps) => {
  return (
    <Box sx={styles.container}>
      <Typography color={(theme) => theme.palette.neutral[100]}>{tournament.name}</Typography>
      <ChevronRight color="secondary" sx={styles.icon} />
    </Box>
  );
};

export default TopOfferItem;
