import Typography, { TypographyProps } from '@mui/material/Typography';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  message: {
    background: (theme) => theme.palette.neutral[50],
    borderBottom: (theme) => `1px solid ${theme.palette.neutral[100]}`,
    py: 0.5,
    fontSize: 11,
  },
};

type BetslipInfoMessageProps = {
  message: string;
};

const BetslipInfoMessage = ({ message, ...rest }: BetslipInfoMessageProps & TypographyProps) => {
  return (
    <Typography variant="body3" textAlign="center" color="neutral.600" sx={styles.message} {...rest}>
      {message}
    </Typography>
  );
};

export default BetslipInfoMessage;
