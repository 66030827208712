import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, ListItem, ListItemButton, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { DrawerContentConfig, StyleObj } from '../../@types';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';

const styles = {
  listItem: {
    p: 0,
    ':hover': {
      backgroundColor: 'primary.800',
    },
    border: 'none',
    transition: 'all 300ms ease',
  },
  listItemButton: {
    px: 2,
    py: 1.5,
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
  },
  accordion: {
    ':hover': {
      backgroundColor: 'primary.700',
    },
    backgroundColor: 'primary.main',
    '&.Mui-expanded': {
      backgroundColor: 'primary.700',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '&::before': {
      height: 0,
    },
    transition: 'all 300ms ease',
  },
  accordionActive: {
    ':hover': {
      backgroundColor: 'primary.800',
    },
    backgroundColor: 'primary.700',
    '&.Mui-expanded': {
      backgroundColor: 'primary.700',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '&::before': {
      height: 0,
    },
    transition: 'all 300ms ease',
  },
  accordionSummary: {
    px: 3,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
  },
  accordionSummaryIcon: {
    color: 'text.primary',
    mr: -0.5,
  },
  divider: {
    borderColor: 'primary.500',
    mx: 3,
  },
} satisfies StyleObj;

type Props = {
  item: DrawerContentConfig;
  navigateTo: (path: string) => void;
};

const SidebarAccordion = ({ item, navigateTo }: Props) => {
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(pathname.split('/')[2] === item.children[0]?.path.split('/')[2]);
  }, [pathname, item]);

  return (
    <Accordion
      sx={item.path === pathname ? styles.accordionActive : styles.accordion}
      expanded={expanded}
      elevation={0}
      onChange={(_, value) => item.children.length && setExpanded(value)}
      disableGutters
      onClick={() => item.path && navigateTo(item.path)}
    >
      <AccordionSummary sx={styles.accordionSummary} expandIcon={<ArrowRightIcon sx={styles.accordionSummaryIcon} />}>
        {item.icon}
        <Typography variant="body2" pl={1}>
          {item.text}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {item.children.map((link, index, arr) => (
          <Fragment key={link.path}>
            <ListItem sx={styles.listItem}>
              <ListItemButton
                disableRipple
                sx={styles.listItemButton}
                onClick={() => navigateTo(link.path)}
                disableGutters
              >
                <Typography variant="body2" fontWeight={link.path === pathname ? 600 : 'initial'} pl={2}>
                  {link.text}
                </Typography>
              </ListItemButton>
            </ListItem>
            {index !== arr.length - 1 && <Divider variant="middle" component="li" sx={styles.divider} />}
          </Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarAccordion;
