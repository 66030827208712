import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as MUI from '@mui/material';
window.reactShared = React;
window.muiShared = MUI;
window.muiBox = Box;
var Example = function () {
    return (_jsxs(Box, { children: [_jsx(Typography, { children: "Example React component with Material UI" }), _jsx(Button, { children: "Button" })] }));
};
export default Example;
