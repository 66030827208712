const ReloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
      <path
        d="M9.99967 3.3335V0.833496L6.66634 4.16683L9.99967 7.50016V5.00016C12.758 5.00016 14.9997 7.24183 14.9997 10.0002C14.9997 10.8418 14.7913 11.6418 14.4163 12.3335L15.633 13.5502C16.283 12.5252 16.6663 11.3085 16.6663 10.0002C16.6663 6.31683 13.683 3.3335 9.99967 3.3335ZM9.99967 15.0002C7.24134 15.0002 4.99967 12.7585 4.99967 10.0002C4.99967 9.1585 5.20801 8.3585 5.58301 7.66683L4.36634 6.45016C3.71634 7.47516 3.33301 8.69183 3.33301 10.0002C3.33301 13.6835 6.31634 16.6668 9.99967 16.6668V19.1668L13.333 15.8335L9.99967 12.5002V15.0002Z"
        fill="#2F2F2F"
      />
    </svg>
  );
};

export default ReloadIcon;
