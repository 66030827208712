import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Grid, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { merge } from 'lodash-es';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { StyleObj } from '../../@types';
import useBreakpoints from '../../hooks/useBreakpoints';
import useRefDimensions from '../../hooks/useRefDimensions';
import CarouselScrollIndicator from '../atoms/CarouselScrollIndicator';

export const commonButtonStyle: SxProps<Theme> = {
  position: 'absolute',
  top: 'calc(50% - 16px)',
  zIndex: 10,
  color: (theme) => theme.palette.neutral[200],
  '&:disabled': {
    color: (theme) => theme.palette.neutral[600],
  },
};

const makeStyles = (translateValue: number | undefined, styleOverride: StyleObj, cms: boolean): StyleObj =>
  merge(
    {
      container: {
        p: {
          xs: 0,
          md: cms ? 0 : 4,
        },
        pb: {
          xs: 0,
          md: cms ? 0 : 3,
        },
        '&:hover .cmsButton': {
          transition: 'all .2s ease',
          color: 'white',
        },
      },
      carousel: {
        transition: 'transform 0.5s ease',
        transform: `translateX(${translateValue}px)`,
        display: 'flex',
        width: '100%',
        gap: {
          xs: 0,
          md: cms ? 0 : '24px',
        },
      },
      prevButton: {
        ...commonButtonStyle,
        left: cms ? 0 : -12,
        '&:hover': cms
          ? {
              color: 'green',
            }
          : '',
        ...(cms && { className: 'cmsButton' }),
      },
      nextButton: {
        ...commonButtonStyle,
        right: cms ? 0 : 12,
        '&:hover': cms
          ? {
              color: 'green',
            }
          : '',
        ...(cms && { className: 'cmsButton' }),
      },
      indicatorContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'absolute',
        top: 70,
        right: 10,
      },
    },
    styleOverride
  );

type Props = {
  children: ReactNode;
  cms?: boolean;
  styleOverride?: StyleObj;
  itemsPerStep?: Record<string, number>;
};

const Carousel = ({ children, styleOverride = {}, cms = false }: Props) => {
  const [step, setStep] = useState(0);
  const [maxStep, setMaxStep] = useState(1);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useRefDimensions(containerRef);
  const styles = makeStyles(-step * width, styleOverride, cms);
  const { isMobile } = useBreakpoints();

  const handlePreviousStep = () => {
    setStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right') {
        handlePreviousStep();
      }

      if (eventData.dir === 'Left' && step !== maxStep - 1) {
        handleNextStep();
      }
    },
    trackMouse: true,
  });

  useEffect(() => {
    const children = Array.from(containerRef.current?.children || []);
    let itemsPerStep = 1;

    if (children?.length && width) {
      itemsPerStep = Math.floor(width / children[0].clientWidth);
    }

    if (itemsPerStep > 0) {
      setMaxStep(Math.ceil(children.length / itemsPerStep));
    }
  }, [width]);

  return (
    <Grid {...handlers} container justifyContent="center" overflow="hidden" position="relative" sx={styles.container}>
      <Grid item xs={12} overflow="hidden">
        <Stack direction="row" sx={styles.carousel} ref={containerRef}>
          {children}
        </Stack>
      </Grid>
      {(cms || !isMobile) && (
        <>
          <IconButton disableRipple onClick={handlePreviousStep} disabled={step === 0} sx={styles.prevButton}>
            <ChevronLeft />
          </IconButton>
          {step !== maxStep - 1 && (
            <IconButton disableRipple onClick={handleNextStep} sx={styles.nextButton}>
              <ChevronRight />
            </IconButton>
          )}
        </>
      )}
      {!cms && isMobile && maxStep > 1 && (
        <Stack sx={styles.indicatorContainer}>
          {[...Array(maxStep)].map((_, index) => (
            <CarouselScrollIndicator key={index} isActive={index === step} />
          ))}
        </Stack>
      )}
    </Grid>
  );
};

export default Carousel;
