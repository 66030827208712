import { Grid } from '@mui/material';
import { useSports } from '../../queries';
import Heading from '../atoms/Heading';
import FeaturedEventsCarousel from '../molecules/FeaturedEventsCarousel';
import FeaturedTournamentCarousel from '../molecules/FeaturedTournamentCarousel';
import Footer from '../molecules/Footer';
import InPlayPreviewTable from '../molecules/InPlayPreviewTable';
import JackpotList from '../molecules/JackpotList';
import TopOfferList from '../molecules/TopOfferList';
import UpcomingEventsTable from '../molecules/UpcomingEventsTable';
import CMSTopContent from '../organisms/CMSTopContent';

const SportsPage = () => {
  const { data: sports } = useSports();

  const featuredSport = sports?.[0];

  return (
    <Grid container>
      <JackpotList />
      <CMSTopContent />
      {!!featuredSport && (
        <Heading direction="column" alignItems="start" spacing={0.5} to={`/sports/${featuredSport.id}`}>
          <Heading.Subtitle>{featuredSport.name}</Heading.Subtitle>
          <Heading.Link>Go To Coupon</Heading.Link>
        </Heading>
      )}
      {!!sports && <FeaturedTournamentCarousel />}
      {!!featuredSport && (
        <>
          <FeaturedEventsCarousel sportId={featuredSport.id} />
          <InPlayPreviewTable sportId={featuredSport.id} />
          <UpcomingEventsTable sportId={featuredSport.id} sportName={featuredSport.name} />
        </>
      )}
      <TopOfferList />
      <Footer />
    </Grid>
  );
};

export default SportsPage;
