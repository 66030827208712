import { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';

export const KEYCLOAK_CONFIG: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
};

export const KEYCLOAK_INIT_OPTIONS: KeycloakInitOptions = {
  onLoad: 'login-required',
};
