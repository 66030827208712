import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FavoriteTournament, PaginatedData, PublishedEventListItem, StyleObj, TopMarketType } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { MessageBetType } from '../../helpers/betslipMessageParser';
import eventListItemUpdater from '../../helpers/eventListItemUpdater';
import useWebsocket, { MessageType } from '../../hooks/useWebsocket';
import { isCheckboxClicked } from '../../utils';
import { getData } from '../../utils/api';
import FavoritesCheckbox from '../atoms/FavoritesCheckbox';

const EXPAND_ICON_WIDTH = 24;

const styles = {
  accordion: {
    backgroundColor: 'neutral.800',
    '& .MuiAccordionDetails-root': {
      cursor: 'pointer',
    },
    '&::before': {
      backgroundColor: 'neutral.600',
    },
    transition: 'all 300ms ease',
  },
  accordionSummary: {
    pl: 3,
    pr: 1.5,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      maxWidth: `calc(100% - ${EXPAND_ICON_WIDTH}px)`,
    },
  },
  accordionSummaryIcon: { color: 'neutral.300' },
  loader: { color: 'neutral.400', mx: 0.5 },
  participantContainer: { alignItems: 'start', flex: '1 1 auto', minWidth: 0 },
  scoreContainer: { flexDirection: 'row', alignItems: 'end', flex: '0 0 auto' },
} satisfies StyleObj;

type Props = {
  tournament: FavoriteTournament;
  selectedEventTournamentId?: string;
  closeDrawer: () => void;
  sportId: string;
};

const InPlaySidebarTournamentAccordion = ({ tournament, selectedEventTournamentId, closeDrawer, sportId }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [events, setEvents] = useState<PublishedEventListItem[]>([]);

  const { data: publishedEventsData, isInitialLoading } = useQuery({
    queryKey: [QUERY_KEYS.publishedEvents, tournament.id],
    queryFn: (): Promise<PaginatedData<PublishedEventListItem> & { topMarketType: TopMarketType }> =>
      getData('events/published', {
        page: 1,
        limit: 100,
        sportId,
        tournamentIds: [tournament.id],
        isActive: true,
        isLive: true,
      }),
    enabled: !!tournament?.id && !!sportId && expanded,
  });

  const updaterCallback = (data: Record<string, MessageType<MessageBetType>>) => {
    const updatedEventId = Object.keys(data)[0];

    setEvents((prevState) => {
      const eventToUpdate = prevState?.find((event) => event.id === updatedEventId);
      if (!eventToUpdate) return prevState;

      const updatedEvent = eventListItemUpdater(eventToUpdate, data[updatedEventId]);

      return prevState.map((event) => (event.id === updatedEventId ? updatedEvent : event));
    });
  };

  const { joinRoom, leaveRoom } = useWebsocket<MessageType<MessageBetType>>({
    callback: updaterCallback,
  });

  // On mount, open tournament accordion containing the event from the event page
  useEffect(() => {
    if (tournament?.id === selectedEventTournamentId) {
      setExpanded(true);
    }
  }, [selectedEventTournamentId, tournament.id]);

  useEffect(() => {
    if (publishedEventsData) {
      setEvents(publishedEventsData.items);
      publishedEventsData?.items?.forEach((event) => {
        joinRoom(`${event.id}_reduced`);
      });
    }
  }, [joinRoom, publishedEventsData]);

  useEffect(() => {
    if (!expanded) {
      events.forEach((event) => {
        leaveRoom(`${event.id}_reduced`);
      });
    }
  }, [expanded, leaveRoom, events]);

  const { eventId } = useParams();
  const navigate = useNavigate();

  const toggleAccordion = (event: React.SyntheticEvent) => {
    if (isCheckboxClicked(event)) return;

    setExpanded((prev) => !prev);
  };

  const handleEventClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    if (isCheckboxClicked(event)) return;

    closeDrawer();
    navigate(`/in-play/match-view/${id}`);
  };

  return (
    <Accordion disableGutters elevation={0} expanded={expanded} onChange={toggleAccordion} sx={styles.accordion}>
      <AccordionSummary
        sx={styles.accordionSummary}
        expandIcon={
          isInitialLoading ? (
            <CircularProgress size={16} sx={styles.loader} />
          ) : (
            <ExpandMore sx={styles.accordionSummaryIcon} />
          )
        }
      >
        <Typography variant="h5" fontWeight={700} noWrap>
          {tournament.name}
        </Typography>
        <FavoritesCheckbox
          id={tournament.id}
          isChecked={tournament.favourite}
          entityType="tournaments"
          variant="medium"
          sx={{ py: 0 }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {events?.map((event) => {
          const isSelectedEvent = event.id === eventId;

          return (
            <Stack
              direction="row"
              gap={1}
              py={1.25}
              pl={3}
              pr={1}
              key={event.id}
              onClick={(e) => handleEventClick(e, event.id)}
            >
              <Typography variant="body3" width={35}>
                {event.matchTime}
              </Typography>
              <Stack sx={styles.participantContainer}>
                <Typography variant="body3" fontWeight={isSelectedEvent ? 700 : 400} noWrap maxWidth="100%">
                  {event.participants?.home?.name || event.participants?.home?.shortName}
                </Typography>
                <Typography variant="body3" fontWeight={isSelectedEvent ? 700 : 400} noWrap maxWidth="100%">
                  {event.participants?.away?.name || event.participants?.away?.shortName}
                </Typography>
              </Stack>
              <Stack sx={styles.scoreContainer}>
                <Stack>
                  <Typography
                    variant="body3"
                    fontWeight={700}
                    noWrap
                    textAlign="right"
                    color={isSelectedEvent ? 'secondary' : 'inherit'}
                  >
                    {event.mainEventPartScore?.valueHome}
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={700}
                    noWrap
                    textAlign="right"
                    color={isSelectedEvent ? 'secondary' : 'inherit'}
                  >
                    {event.mainEventPartScore?.valueAway}
                  </Typography>
                </Stack>
                <FavoritesCheckbox id={event.id} isChecked={event.favourite} entityType="events" variant="medium" />
              </Stack>
            </Stack>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default InPlaySidebarTournamentAccordion;
