import { isEmpty } from 'lodash-es';
import { Market } from '../@types';
import { MessageType } from '../hooks/useWebsocket';

export type MessageBetType = {
  odds?: string;
  id: string;
  isActive?: boolean;
};

const topMarketUpdater = (prev: Market | null, next: MessageType<MessageBetType>): Market | null => {
  if (!prev || !next) {
    return prev;
  }

  const { event, payload } = next;

  if (isEmpty(event) || isEmpty(payload)) {
    return prev;
  }

  if (next.event === 'OUTCOME') {
    const outcomeOddsUpdated = 'odds' in payload;
    const outcomeStatusUpdated = 'isActive' in payload;

    const outcomeIndex = prev.outcomes.findIndex((o) => o.id === payload.id);

    if (outcomeIndex !== undefined && outcomeIndex !== -1 && prev) {
      if (outcomeOddsUpdated && payload.odds) {
        prev.outcomes[outcomeIndex].odds = payload.odds;
      }
      if (outcomeStatusUpdated) {
        prev.outcomes[outcomeIndex].isActive = payload.isActive || false;
      }
    }
  }

  if (next.event === 'MARKET') {
    if (prev && 'isActive' in next.payload) {
      prev.isActive = next.payload.isActive || false;
    }
  }

  return { ...prev };
};

export default topMarketUpdater;
