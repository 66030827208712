import { omit } from 'lodash-es';
import { useAuth } from 'neofusion-fe-shared';
import { useSnackbar } from 'notistack';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Bet,
  BetslipResolutionData,
  BetslipResolutionDataPayload,
  BetslipTicketType,
  MarketType,
  SpecialValue,
} from '../@types';
import { BETSLIP_TICKET_TYPES, QUERY_KEYS } from '../constants';
import { MESSAGES } from '../constants/messages';
import { analyzeBetslip } from '../helpers/';
import betslipMessageParser, {
  MarketChangeStatus,
  MatchChangeStatus,
  MessageBetType,
  OddsChangeStatus,
  OutcomeChangeStatus,
} from '../helpers/betslipMessageParser';
import { useInvalidateQuery } from '../hooks/useInvalidateQuery';
import useWebsocket, { MessageType } from '../hooks/useWebsocket';
import { useGlobalTicketConditions } from '../queries';

export type BetslipEvent = Bet & {
  eventName: string;
  matchChange?: MatchChangeStatus;
  marketId?: string;
  marketName: string;
  marketType?: MarketType;
  marketChange?: MarketChangeStatus;
  outcomeChange?: OutcomeChangeStatus;
  outcomeName: string;
  outcomeShortName?: string;
  odds: string;
  oddsChange?: OddsChangeStatus;
  specialValues?: SpecialValue[];
  disabled?: boolean;
  isLive?: boolean;
};

type BetslipContextData = {
  bets: BetslipEvent[];
  addBet: (bet: BetslipEvent) => void;
  addOrRemoveBet: (bet: BetslipEvent) => void;
  removeBet: (outcomeId: string) => void;
  removeAllBets: () => void;
  isOutcomeSelected: (outcomeId: string) => boolean;
  toggleBanker: (outcomeId: string) => void;
  errors: string[];
  clearErrors: () => void;
  uniqueEventCount: number;
  isWaysTicket: boolean;
  resetBankers: () => void;
  currentBankerCount: number;
  isMaxBankerCountReached: boolean;
  infoMessages: Set<string>;
  insertCopiedBetslip: (bets: BetslipEvent[]) => void;
  updateSinglesStakeAmount: (outcomeId: string, amount: string) => void;
  resetSinglesStakeAmounts: () => void;
  joinUserRoom: () => void;
  reofferedBettingSlipsIds: string[];
  removeReofferedBettingSlip: (bettingSlipId: string) => void;
  setBettingPrevented: (value: boolean) => void;
  betslipTicketType: BetslipTicketType;
};

export const BetslipContext = createContext<BetslipContextData | undefined>(undefined);

type BetslipProviderProps = {
  children: ReactNode;
};

// FIXME: improve this function
const checkIsInBetslip = (bet: BetslipEvent, message: MessageType<MessageBetType>) => {
  if (!message) return false;

  const { event, payload } = message;
  if (!payload || !event) return false;

  return (
    (event === 'OUTCOME' && payload.id === bet.outcomeId) ||
    (event === 'MARKET' && payload.id === bet.marketId) ||
    payload.id === bet.eventId
  );
};

const checkIsDeactivated = (bet: BetslipEvent) => {
  return (
    bet.outcomeChange === OutcomeChangeStatus.deactivated ||
    bet.marketChange === MarketChangeStatus.deactivated ||
    bet.matchChange === MatchChangeStatus.deactivated
  );
};

export const BetslipProvider = ({ children }: BetslipProviderProps) => {
  const [bets, setBets] = useState<Record<string, BetslipEvent>>({});
  const [reofferedBettingSlipsIds, setReofferedBettingSlipsIds] = useState<string[]>([]);
  const [bettingPrevented, setBettingPrevented] = useState(false);

  const [errors, setErrors] = useState<{ outcomeId: string; message: string }[]>([]);
  const [infoMessages, setInfoMessages] = useState<Set<string>>(new Set());

  const { enqueueSnackbar } = useSnackbar();
  const invalidateData = useInvalidateQuery();

  const { userId } = useAuth();

  const { data: globalTicketConditions } = useGlobalTicketConditions();

  const { betslipTicketType } = useMemo(() => analyzeBetslip(Object.values(bets)), [bets]);

  const updaterCallback = (data: Record<string, MessageType<MessageBetType>>) => {
    setBets((prevState) => {
      const newState = Object.entries(prevState).reduce((acc, [outcomeId, bet]) => {
        const message = data[bet.eventId];

        const isBetInBetslip = checkIsInBetslip(bet, message);

        if (!isBetInBetslip) {
          return acc;
        }

        const updatedBet = betslipMessageParser(bet, message);

        // if disabled set singlesStakeAmount to undefined
        if (updatedBet.disabled) {
          updatedBet.singlesStakeAmount = undefined;
        }

        return { ...acc, [outcomeId]: updatedBet };
      }, {});

      return { ...prevState, ...newState };
    });
  };

  const { joinRoom, leaveRoom, resetWS } = useWebsocket<MessageBetType>({
    callback: updaterCallback,
    // neccessary because we are receiving multiple messages at once when multiple outcomes are updated
  });

  useEffect(() => {
    const newErrors: { outcomeId: string; message: string }[] = [];

    Object.values(bets).forEach((bet) => {
      if (checkIsDeactivated(bet)) {
        newErrors.push({ outcomeId: bet.outcomeId, message: MESSAGES.availabilityChanged });
        return;
      }
      if (bet.oddsChange) {
        newErrors.push({ outcomeId: bet.outcomeId, message: MESSAGES.outcome });
      }
    });

    setErrors(newErrors);
  }, [bets]);

  const addBet = (newBet: BetslipEvent) => {
    if (bettingPrevented) {
      enqueueSnackbar(MESSAGES.notAllowedToBet, {
        variant: 'info',
      });
      return;
    }

    const betType = newBet.isLive ? BETSLIP_TICKET_TYPES.inPlay : BETSLIP_TICKET_TYPES.preMatch;

    // We check if the ticket is already a mixed ticket, or if by adding a new bet of a different type it would become a mixed ticket
    const isMixTicket = betslipTicketType === BETSLIP_TICKET_TYPES.mix || betType !== betslipTicketType;

    const maxSelections = isMixTicket
      ? globalTicketConditions?.[BETSLIP_TICKET_TYPES.mix].maxSelections
      : globalTicketConditions?.[betType]?.maxSelections;
    const canAddBet = !maxSelections || betCount < maxSelections;

    if (canAddBet) {
      setBets((prevState) => ({ ...prevState, [newBet.outcomeId]: { ...newBet, banker: false } }));
      joinRoom(newBet.eventId);
    } else {
      setTimedInfoMessage(MESSAGES.maxSelections, 5000);
    }
  };

  const removeInfoMessage = (messageToRemove: string) => {
    if (infoMessages.has(messageToRemove)) {
      setInfoMessages((prevMessages) => {
        const newMessages = new Set(prevMessages);
        newMessages.delete(messageToRemove);
        return newMessages;
      });
    }
  };

  const removeBet = (outcomeId: string) => {
    const eventToRemove = bets[outcomeId].eventId;
    setBets((prevState) => omit(prevState, outcomeId));
    removeInfoMessage(MESSAGES.maxSelections);
    leaveRoom(eventToRemove);

    setErrors((prev) => prev.filter((error) => error.outcomeId !== outcomeId));
  };

  const addOrRemoveBet = (bet: BetslipEvent) => {
    if (bettingPrevented) {
      enqueueSnackbar(MESSAGES.notAllowedToBet, {
        variant: 'info',
      });
      return;
    }
    if (isOutcomeSelected(bet.outcomeId)) {
      removeBet(bet.outcomeId);
    } else {
      addBet(bet);
    }
  };

  const removeAllBets = () => {
    setBets({});
    resetWS(); // ovde treba sa
    removeInfoMessage(MESSAGES.maxSelections);
    setErrors([]);
  };

  const isOutcomeSelected = (outcomeId: string) => !!bets[outcomeId];

  const uniqueEventCount = new Set(Object.values(bets).map((bet) => bet.eventId)).size;
  const currentBankerCount = Object.values(bets).filter((bet) => bet.banker).length;

  const betCount = Object.values(bets).length;
  const maxBankerCount = betCount - 2;
  const isMaxBankerCountReached = betCount > 2 && currentBankerCount >= maxBankerCount;

  useEffect(() => {
    setInfoMessages((prevMessages) => {
      const newMessages = new Set(prevMessages);
      if (isMaxBankerCountReached) {
        newMessages.add(MESSAGES.maxBankers);
      } else {
        newMessages.delete(MESSAGES.maxBankers);
      }
      return newMessages;
    });
  }, [isMaxBankerCountReached]);

  const isWaysTicket = uniqueEventCount !== Object.values(bets).length;

  const clearErrors = () => {
    setErrors([]);
    acceptChanges();
  };

  const acceptChanges = () => {
    setBets((prevState) => {
      const newState: Record<string, BetslipEvent> = {};

      Object.entries(prevState).forEach(([key, value]) => {
        newState[key] = {
          ...value,
          oddsChange: 0,
          outcomeChange: 0,
          marketChange: 0,
          matchChange: 0,
        };
      });

      return newState;
    });
  };

  const toggleBanker = (outcomeId: string) => {
    setBets((prevState) => ({
      ...prevState,
      [outcomeId]: {
        ...prevState[outcomeId],
        banker: !prevState[outcomeId].banker,
      },
    }));
  };

  const resetBankers = useCallback(() => {
    setBets((prevState) => {
      const newState: Record<string, BetslipEvent> = {};

      Object.entries(prevState).forEach(([key, value]) => {
        newState[key] = {
          ...value,
          banker: false,
        };
      });

      return newState;
    });
  }, []);

  const setTimedInfoMessage = useCallback((message: string, duration = 3000) => {
    setInfoMessages((prevMessages) => new Set(prevMessages).add(message));

    setTimeout(() => {
      setInfoMessages((prevMessages) => {
        const updatedMessages = new Set(prevMessages);
        updatedMessages.delete(message);
        return updatedMessages;
      });
    }, duration);
  }, []);

  const insertCopiedBetslip = (copiedBets: BetslipEvent[]) => {
    removeAllBets();

    setBets(() => {
      const newState: Record<string, BetslipEvent> = {};

      copiedBets.forEach((bet) => {
        newState[bet.outcomeId] = bet;
        joinRoom(bet.outcomeId);
      });

      return newState;
    });

    setTimedInfoMessage(MESSAGES.ticketCopied);
  };

  const updateSinglesStakeAmount = useCallback((outcomeId: string, amount: string) => {
    setBets((prevState) => ({
      ...prevState,
      [outcomeId]: {
        ...prevState[outcomeId],
        singlesStakeAmount: amount,
      },
    }));
  }, []);

  const resetSinglesStakeAmounts = useCallback(() => {
    setBets((prevState) => {
      const newState: Record<string, BetslipEvent> = {};

      Object.entries(prevState).forEach(([key, value]) => {
        newState[key] = {
          ...value,
          singlesStakeAmount: undefined,
        };
      });

      return newState;
    });
  }, []);

  const removeReofferedBettingSlip = (bettingSlipId: string) => {
    setReofferedBettingSlipsIds((prev) => prev.filter((id) => id !== bettingSlipId));
  };

  const userIdRoomUpdaterCallback = (data: BetslipResolutionData) => {
    const messageData = data[userId];

    if (!messageData) {
      return;
    }

    if (messageData.payload.acceptStatus === 'rejected') {
      if (messageData.payload?.reofferedId) {
        setReofferedBettingSlipsIds((prev) => [...prev, messageData.payload.reofferedId as string]);
      } else {
        enqueueSnackbar(MESSAGES.bettingSlipRejected, {
          variant: 'warning',
        });
        // we need to check if any of the betslips is in confirmation process and
        // if so, keep the bettingPrevented flag to true
        setBettingPrevented(false);
      }
    } else if (messageData.payload.acceptStatus === 'accepted') {
      enqueueSnackbar(MESSAGES.placeBetSuccess, {
        variant: 'success',
      });
      // we need to check if any of the betslips is in confirmation process and
      // if so, keep the bettingPrevented flag to true
      setBettingPrevented(false);
    }

    if (messageData.payload.acceptStatus === 'admin_cancelled') {
      enqueueSnackbar(MESSAGES.bettingSlipRejected, {
        variant: 'warning',
      });

      invalidateData([QUERY_KEYS.balance, QUERY_KEYS.myBetsCount]);
    }
  };

  const { joinRoom: joinUserIdRoom } = useWebsocket<BetslipResolutionDataPayload>({
    callback: userIdRoomUpdaterCallback,
  });

  const joinUserRoom = () => {
    joinUserIdRoom(userId);
  };

  const contextValue: BetslipContextData = {
    bets: Object.values(bets),
    addBet,
    removeBet,
    removeAllBets,
    isOutcomeSelected,
    addOrRemoveBet,
    toggleBanker,
    errors: Array.from(new Set(errors?.map((error) => error.message))),
    clearErrors,
    uniqueEventCount,
    isWaysTicket,
    resetBankers,
    currentBankerCount,
    isMaxBankerCountReached,
    infoMessages,
    insertCopiedBetslip,
    updateSinglesStakeAmount,
    resetSinglesStakeAmounts,
    joinUserRoom,
    reofferedBettingSlipsIds,
    removeReofferedBettingSlip,
    setBettingPrevented,
    betslipTicketType,
  };

  return <BetslipContext.Provider value={contextValue}>{children}</BetslipContext.Provider>;
};

export const useBetslip = () => {
  const context = useContext(BetslipContext);
  if (!context) {
    throw new Error('useBetslip must be used within a BetslipProvider');
  }
  return context;
};
