import { Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Outcome, PublishedEventListItem, StyleObj } from '../../@types';
import { useBetslip } from '../../contexts/BetslipContext';
import useBreakpoints from '../../hooks/useBreakpoints';
import EventCardOutcomeCell from './EventCardOutcomeCell';

const CARD_MARGIN = 24;

const makeStyles = (isMobile: boolean): StyleObj => ({
  container: {
    backgroundColor: {
      xs: 'neutral.700',
      md: 'neutral.600',
    },
    borderRadius: {
      md: 1,
    },
    '&:nth-of-type(odd):not(:last-child)': {
      borderRight: (theme) => ({
        xs: `1px solid ${theme.palette.primary.main}`,
        md: 0,
      }),
    },
    minWidth: {
      xs: `100%`,
      sm: 'calc(100% / 2)',
      md: `calc(100% / 2 - ${CARD_MARGIN}px)`,
      lg: `calc(100% / 3 - ${CARD_MARGIN}px)`,
    },
    maxWidth: 400,
    overflow: 'hidden',
  },
  content: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: isMobile ? 'start' : 'center',
    justifyContent: 'center',
    px: {
      xs: 1,
      md: 2,
    },
    pt: {
      xs: 1,
      md: 2,
    },
    pb: {
      xs: 1,
      md: 2,
    },
    gap: {
      xs: 0.5,
      md: 1.5,
    },
    borderBottom: (theme) => `1px solid ${theme.palette.neutral[700]}`,
  },
  eventParticipants: {
    width: 1,
    flexDirection: isMobile ? 'column' : 'row',
    gap: isMobile ? 0.75 : 2,
    justifyContent: 'center',
  },
  startDate: {
    color: {
      xs: '#FFFFFF60',
      md: '#FFF',
    },
    fontSize: { xs: 9, md: 12 },
    fontWeight: { xs: 400, md: 700 },
    letterSpacing: '0.5px',
  },
});

type EventCardProps = { event: PublishedEventListItem };

const EventCard = ({ event }: EventCardProps) => {
  const { startDate, participants, topMarket } = event;

  const { addOrRemoveBet } = useBetslip();
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();

  const styles = makeStyles(isMobile);

  const addToBetslip = (outcome: Outcome) => {
    if (!event.topMarket) return;

    addOrRemoveBet({
      outcomeId: outcome.id,
      odds: outcome.odds,
      eventName: event.name,
      marketId: event.topMarket.id,
      marketName: event.topMarket.shortName ?? event.topMarket?.name,
      outcomeName: outcome.name,
      outcomeShortName: outcome.shortName,
      eventId: event.id,
      specialValues: event.topMarket.specialValues,
      marketType: event.topMarket.marketType,
      isLive: event.isLive,
    });
  };

  const handleContentClick = () => {
    navigate(`/sports/events/${event.id}`);
  };

  return (
    <Grid container alignItems="center" sx={styles.container}>
      <Grid item xs={12} sx={styles.content} onClick={handleContentClick}>
        <Stack sx={styles.eventParticipants}>
          <Typography noWrap variant="body2" title={participants?.home?.name}>
            {participants?.home?.name}
          </Typography>
          {!isMobile && <Typography variant="body2">v</Typography>}
          <Typography noWrap variant="body2" title={participants?.away?.name}>
            {participants?.away?.name}
          </Typography>
        </Stack>
        <Typography variant="h6" sx={styles.startDate}>
          {dayjs(startDate).format('dddd HH:mm')}
        </Typography>
      </Grid>
      {topMarket?.outcomes.map((outcome) => (
        <EventCardOutcomeCell
          key={outcome.id}
          outcome={outcome}
          outcomeCount={topMarket.outcomes.length}
          onClick={() => addToBetslip(outcome)}
        />
      ))}
    </Grid>
  );
};

export default EventCard;
