import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { StakeErrorType, StyleObj } from '../../@types';
import NumberInput from './NumberInput';
import { parseNumberWithDecimals, prepareStakeMessages } from '../../helpers';
import { useBetslip } from '../../contexts/BetslipContext';
import { useGlobalTicketConditions } from '../../queries';
import { CURRENCY } from '../../constants';
import { useEffect } from 'react';

const styles: StyleObj = {
  betslipInfo: {
    background: (theme) => theme.palette.neutral[100],
    borderBottom: '1px solid #D9D9D9',
    py: 1,
    px: 1.5,
    height: 40,
  },
  stakeInput: {
    width: 90,
    height: 40,
    flexDirection: 'row',
    '& fieldset': { border: 'none' },
    '& .MuiInputBase-input': {
      color: (theme) => theme.palette.primary.main,
      textAlign: 'right',
      fontWeight: 600,
      fontSize: 14,
    },

    background: 'white',
  },
};

type BetslipInputProps = {
  bettingType?: string;
  odds?: string;
  numberOfCombinations?: number;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: StakeErrorType;
};

const BetslipInput = ({
  onChange,
  value,
  bettingType,
  odds,
  numberOfCombinations,
  errorMessage,
}: BetslipInputProps) => {
  const { betslipTicketType } = useBetslip();
  const { data: globalTicketConditions } = useGlobalTicketConditions();
  const stakeMessage = prepareStakeMessages(betslipTicketType, globalTicketConditions, errorMessage);
  const { type: stakeErrorType, amount: stakeErrorAmount } = stakeMessage ?? {};

  useEffect(() => {
    //trigger update of the stake value when odds are updated
    onChange(value);
  }, [odds, numberOfCombinations]);

  return (
    <Stack direction="row" alignItems="center">
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1} sx={styles.betslipInfo}>
        <Typography variant="h5" color="neutral.600">
          {bettingType} {odds}
        </Typography>
        {stakeErrorType && (
          <Typography variant="body3" fontSize={9} textAlign="center" color="error.600">
            {stakeErrorType} is {CURRENCY.symbol}
            {stakeErrorAmount && parseNumberWithDecimals(stakeErrorAmount, 2)}
          </Typography>
        )}
        <Typography variant="body2" color="neutral.600">
          {numberOfCombinations}x
        </Typography>
      </Stack>
      <NumberInput
        size="small"
        variant="standard"
        placeholder="Stake"
        sx={styles.stakeInput}
        onChange={onChange}
        value={value as string}
      />
    </Stack>
  );
};

export default BetslipInput;
