const DiamondIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M13.2676 1.5H3.73244L1 5.59863V6.37319L6.73191 15H10.2681L16 6.37319V5.59863L13.2676 1.5ZM12.7324 2.5L14.7139 5.47222H11.3668L10.4315 2.5H12.7324ZM7.61681 2.5H9.38319L10.3185 5.47222H6.6815L7.61681 2.5ZM4.26759 2.5H6.5685L5.63316 5.47222H2.28609L4.26759 2.5ZM7.26809 14L2.26641 6.47222H5.60369L7.36916 14H7.26809ZM8.39622 14L6.63084 6.47222H10.3692L8.60369 14H8.39622ZM9.73181 14H9.63075L11.3964 6.47222H14.7336L9.73181 14Z"
        fill="#FEDA2B"
      />
    </svg>
  );
};

export default DiamondIcon;
